import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import TimelineStepsToFinance from './components/TimelineStepsToFinance';
import PilotBackground from './components/PilotBackground';
import QuickFlippyRegister from './components/QuickFlippyRegister';

const pictures = [
  'https://d2k677lw6yfmkw.cloudfront.net/images/logo_santander.png',
  'https://d2k677lw6yfmkw.cloudfront.net/images/logo_wild_brand.png',
  'https://d2k677lw6yfmkw.cloudfront.net/images/logo_acepta.png',
  'https://d2k677lw6yfmkw.cloudfront.net/images/logo_pochteca.png',
  'https://d2k677lw6yfmkw.cloudfront.net/images/logo_chucao.png',
  'https://d2k677lw6yfmkw.cloudfront.net/images/logo_senegocia.png',
];

const LandingRegisterWithClientImage = () => (
  <>
    <Box
      height={{
        xs: 'fit-content',
        md: '85%',
      }}
      minHeight={560}
    >
      <PilotBackground showPlane>
        <Grid
          container
          py={{
            xs: 1,
          }}
          rowSpacing={2}
          bgcolor={{
            xs: 'fingoWhite.main',
            md: 'inherit',
          }}
          direction={{
            xs: 'column-reverse',
            md: 'row',
          }}
        >
          <Grid
            container
            item
            xs={12}
            md={6}
            px={4}
            alignItems="flex-start"
            justifyContent="center"
          >
            <Box
              maxWidth={550}
              mt={{
                md: 4,
              }}
            >
              <Typography variant="h1" paragraph align="left" width="100%">
                <Typography variant="h1" component="span" color="primary">¡Obtén financiamiento</Typography>
                {' '}en menos de 1 día!
              </Typography>
              <Typography variant="h4" paragraph align="left" width="100%">
                En 4 simples pasos:
              </Typography>
              <Box
                width="100%"
                bgcolor="fingoWhite.main"
                borderRadius={4}
                px={2}
                py={4}
              >
                <TimelineStepsToFinance />
              </Box>
            </Box>
          </Grid>
          <Grid
            container
            item
            xs={12}
            md={6}
            px={4}
            justifyContent={{
              xs: 'center',
              md: 'flex-start',
            }}
          >
            <Typography display={{ md: 'none' }} variant="h1" align="center" paragraph>
              <Typography component="span" color="primary" variant="h1">
                Regístrate
              </Typography>
              {' '}en Fingo
            </Typography>
            <QuickFlippyRegister />
          </Grid>
        </Grid>
      </PilotBackground>
    </Box>
    <Box height="15%" bgcolor="fingoWhite.main">
      <Grid item container xs={12} alignItems="center" justifyContent="center" height="100%">
        <Typography
          variant="h5"
          sx={{ textDecoration: 'underline' }}
          width={{
            xs: '100%',
            md: 'auto',
          }}
          align="center"
        >
          Confían en nosotros:
        </Typography>
        {pictures.map((url) => (
          <Box
            width={{
              xs: 90, md: 110,
            }}
            ml={{
              xs: 1,
              md: 4,
            }}
            key={url}
          >
            <img src={url} alt="client-working" style={{ width: '100%' }} />
          </Box>
        ))}
      </Grid>
    </Box>
  </>
);

export default LandingRegisterWithClientImage;
