import React from 'react';
import PunchClockOutlinedIcon from '@mui/icons-material/PunchClockOutlined';
import AutoGraphOutlinedIcon from '@mui/icons-material/AutoGraphOutlined';
import ComputerOutlinedIcon from '@mui/icons-material/ComputerOutlined';
import Face2OutlinedIcon from '@mui/icons-material/Face2Outlined';
import { RowDisplayWithIcons } from '@fingo/lib/components/timeline';

const items = [
  {
    title: 'Rápido',
    subtitle: 'en un día',
    Icon: PunchClockOutlinedIcon,
  },
  {
    title: 'Conveniente',
    subtitle: 'las tasas más bajas',
    Icon: AutoGraphOutlinedIcon,
  },
  {
    title: 'Sin papeleo',
    subtitle: '100% digital',
    Icon: ComputerOutlinedIcon,
  },
  {
    title: 'Amigable',
    subtitle: 'de fácil uso',
    Icon: Face2OutlinedIcon,
  },
];

const TimelineStepsToEvaluateChurn = () => (
  <RowDisplayWithIcons items={items} />
);

export default TimelineStepsToEvaluateChurn;
