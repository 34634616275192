import React from 'react';
import ForwardIcon from '@mui/icons-material/Forward';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import MouseIcon from '@mui/icons-material/Mouse';
import TimelineWithIcons from '@fingo/lib/components/timeline/TimelineWithIcons';
import PropTypes from 'prop-types';

const TimelineStepsToEvaluateChurn = ({ isLogged }) => {
  const loggedStep = isLogged ? 'Entra en la app' : 'Inicia sesión en el formulario de la derecha';

  const steps = [
    {
      text: loggedStep,
      Icon: ForwardIcon,
    },
    {
      text: 'Selecciona tus facturas a evaluar',
      Icon: MouseIcon,
    },
    {
      text: 'Recibe aprobación de riesgo',
      Icon: CheckRoundedIcon,
    },
    {
      text: '¡Recibe tu dinero!',
      Icon: PaidOutlinedIcon,
    },
  ];

  return (
    <TimelineWithIcons steps={steps} color="light-grey" isLogged={isLogged} />
  );
};

TimelineStepsToEvaluateChurn.propTypes = {
  isLogged: PropTypes.bool,
};

TimelineStepsToEvaluateChurn.defaultProps = {
  isLogged: false,
};

export default TimelineStepsToEvaluateChurn;
