import { WaveFullHeightBox } from '@fingo/lib/components/boxes';
import React from 'react';
import PropTypes from 'prop-types';
import FloatingPlane from './FloatingPlane';

const PilotBackground = ({ children, showPlane }) => (
  <WaveFullHeightBox>
    {showPlane && <FloatingPlane />}
    {children}
  </WaveFullHeightBox>
);

PilotBackground.propTypes = {
  children: PropTypes.node.isRequired,
  showPlane: PropTypes.bool,
};

PilotBackground.defaultProps = {
  showPlane: false,
};

export default PilotBackground;
