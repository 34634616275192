import { StepCard } from '@fingo/lib/components/cards';
import { Grid, Stack, Typography } from '@mui/material';
import React from 'react';

const steps = [
  {
    number: 1,
    text: 'Inicia sesión en el formulario de la derecha',
    appearDelay: 0.1,
  },
  {
    number: 2,
    text: 'Selecciona tu factura a evaluar',
    appearDelay: 0.4,
  },
  {
    number: 3,
    text: 'Recibe aprobación de riesgo',
    appearDelay: 0.7,
  },
  {
    number: 4,
    text: '¡Recibe tu dinero!',
    appearDelay: 1,
  },
];

const StepsToFinance = () => (
  <Stack
    spacing={{
      xs: 0.5,
      md: 2,
    }}
    width={600}
    alignItems="center"
  >
    <Typography variant="h2" color="primary" zIndex="inherit" fontSize={{ xs: '1.25rem', md: '1.6rem' }}>
      Obtén tu primera operación <Typography component="span" display="inline" color="text.primary" variant="h1">con tasa 0.99%</Typography>
    </Typography>
    <Typography
      variant="subtitle2"
      fontSize={{ xs: '0.9rem', md: '1.2rem' }}
      color="primary"
      zIndex="inherit"
    >
      Sigue estos simples 4 pasos ¡Es gratis!
    </Typography>
    <Grid
      container
      spacing={{
        xs: 1,
        md: 2,
      }}
    >
      {steps.map((step) => (
        <Grid
          key={step.number}
          item
          xs={12}
          md={6}
          sx={{
            opacity: 1,
            transition: 'opacity 1s ease-in-out',
            transitionDelay: `${step.appearDelay}s`,
          }}
        >
          <StepCard
            stepNumber={step.number}
            text={step.text}
          />
        </Grid>
      ))}
    </Grid>
  </Stack>
);

export default StepsToFinance;
