import { useBooleanState } from '@fingo/lib/hooks';
import { Box, Grid, Paper, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import { BackSide, Flippy, FrontSide } from 'react-flippy';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import EvaluateNoRegister from './EvaluateNoRegister';
import QuickRegisterComponent from './QuickRegisterComponent';

const QuickFlippyRegister = ({ initialFlipped, paperFullWidth }) => {
  const [flipped, toggleFlipped] = useBooleanState(initialFlipped);
  const history = useHistory();
  const onRegisterCompleted = () => history.replace({ pathname: '/app/home' });
  const paperWidth = useMemo(() => {
    if (paperFullWidth) return '100%';
    return { xs: 300, sm: 400 };
  }, [paperFullWidth]);
  const boxShadow = useMemo(() => {
    if (paperFullWidth) return 'none';
    return null;
  }, [paperFullWidth]);
  return (
    <Box width={paperFullWidth ? '100%' : 'fit-content'}>
      <Flippy
        flipOnClick={false}
        isFlipped={flipped}
        style={{
          margin: 0,
          boxShadow: 'none',
          padding: 0,
        }}
      >
        <FrontSide
          style={{
            padding: '0px',
            boxShadow: 'none',
          }}
        >
          <Paper
            sx={{
              width: paperWidth,
              height: '100%',
              pt: 3,
              px: { xs: 3, md: 7 },
              boxShadow,
            }}
            elevation={4}
          >
            <QuickRegisterComponent onRegisterCompleted={onRegisterCompleted} />
            <Grid
              onClick={toggleFlipped}
              sx={{
                py: 2,
                cursor: 'pointer',
              }}
            >
              <Typography
                variant="h5"
                textAlign="center"
              >
                Si no quieres registrarte, envíanos una factura haciendo click aquí:
              </Typography>
              <Typography
                variant="h6"
                textAlign="center"
                color="primary"
                sx={{
                  textDecorationLine: 'underline',
                }}
              >
                Evaluar sin registrarme
              </Typography>
            </Grid>
          </Paper>
        </FrontSide>
        <BackSide
          style={{
            boxShadow: 'none',
          }}
        >
          <Paper
            sx={{
              width: paperWidth,
              height: '100%',
              pt: 3,
              px: { xs: 2, md: 6 },
              boxShadow,
            }}
            elevation={4}
          >
            <Typography
              variant="subtitle2"
              textAlign="left"
              color="primary"
              sx={{
                cursor: 'pointer',
              }}
              onClick={toggleFlipped}
            >
              &lt; Volver
            </Typography>
            <br />
            <br />
            <EvaluateNoRegister onCompleted={toggleFlipped} />
          </Paper>
        </BackSide>
      </Flippy>
    </Box>
  );
};

QuickFlippyRegister.propTypes = {
  initialFlipped: PropTypes.bool,
  paperFullWidth: PropTypes.bool,
};

QuickFlippyRegister.defaultProps = {
  initialFlipped: false,
  paperFullWidth: false,
};

export default QuickFlippyRegister;
