import { useMutation } from '@apollo/client';
import RegisterTextField from '@fingo/lib/components/inputs/RegisterTextField';
import { NO_REGISTER_USER } from '@fingo/lib/graphql';
import { getFormFieldError } from '@fingo/lib/helpers';
import { useSnackBars } from '@fingo/lib/hooks';
import Check from '@mui/icons-material/Check';
import Close from '@mui/icons-material/Close';
import FileUpload from '@mui/icons-material/FileUpload';
import { LoadingButton } from '@mui/lab';
import { Button, InputAdornment, Link, Stack, Typography } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

const EvaluateNoRegister = ({ onCompleted }) => {
  const setEndAdornment = useCallback(({ element }) => {
    if (element.value !== '') {
      if (element.error === '') {
        return <InputAdornment position="end"><Check color="success" /></InputAdornment>;
      }
      return <InputAdornment position="end"><Close color="error" /></InputAdornment>;
    }
    return null;
  }, []);
  const { t } = useTranslation();
  const location = useLocation();
  const urlSearch = new URLSearchParams(location.search);
  const { addAlert } = useSnackBars();
  const [noRegisterForm, setNoRegisterForm] = useState({
    phoneNumber: {
      value: urlSearch.get('phoneNumber') || '',
      error: '',
    },
    file: {
      value: null,
      error: '',
    },
  });
  const [noRegisterMutation, { loading: loadingNoRegister }] = useMutation(NO_REGISTER_USER, {
    variables: {
      phoneNumber: noRegisterForm.phoneNumber.value,
      file: noRegisterForm.file.value,
    },
    onError: () => {
      addAlert({
        id: 'alert-no-register',
        message: 'Lo sentimos, no pudimos resolver tu solicitud. Contáctate con nosotros al contacto@fingo.cl',
        color: 'error',
        severity: 'error',
      });
      onCompleted();
    },
    onCompleted: () => {
      addAlert({
        id: 'alert-no-register',
        message: '¡Envío exitoso! Te enviaremos la simulación a tu teléfono.',
        color: 'success',
        severity: 'success',
      });
      setNoRegisterForm({
        phoneNumber: {
          value: '',
          error: '',
        },
        file: {
          value: null,
          error: '',
        },
      });
      onCompleted();
    },
  });
  const noRegisterHandleChange = useCallback((e) => {
    const { value } = e.target;
    const { name, type, files } = e.target;
    if (name === 'file') {
      setNoRegisterForm((oldState) => ({
        ...oldState,
        [name]: { ...oldState[name], value: files[0] },
      }));
      return;
    }
    const fieldError = getFormFieldError(value, type, name);
    if (fieldError.isError) {
      setNoRegisterForm((oldState) => ({
        ...oldState,
        [name]: { ...oldState[name], error: fieldError.message, value },
      }));
    } else {
      setNoRegisterForm((oldState) => ({
        ...oldState,
        [name]: { ...oldState[name], error: '', value },
      }));
    }
  }, []);
  const noRegisterButtonReady = useMemo(() => !(
    noRegisterForm.phoneNumber.value === ''
      || noRegisterForm.phoneNumber.error !== ''
      || !noRegisterForm.file.value?.name
  ), [noRegisterForm.phoneNumber, noRegisterForm.file]);
  return (
    <Stack
      direction="column"
      spacing={4}
    >
      <Typography
        variant="h5"
        textAlign="center"
      >
        ¿No te quieres registrar y quieres evaluar una factura?
      </Typography>
      <Typography
        variant="h6"
        textAlign="center"
      >
        Ingresa el PDF de tu factura y tu número de teléfono y
        te enviaremos una simulación
      </Typography>
      <RegisterTextField
        name="phoneNumber"
        validationtype="phone"
        type="number"
        label="Número de teléfono"
        variant="standard"
        value={noRegisterForm.phoneNumber.value}
        onChange={noRegisterHandleChange}
        InputProps={{
          startAdornment: <InputAdornment position="start">+{t('Phone prefix')}</InputAdornment>,
          endAdornment: (
            setEndAdornment({ element: noRegisterForm.phoneNumber })
          ),
        }}
      />
      <label htmlFor="file" key="file">
        <input
          id="file"
          type="file"
          name="file"
          onChange={noRegisterHandleChange}
          style={{ display: 'none' }}
        />
        <Button
          name="file"
          component="span"
          color="primary"
          startIcon={<FileUpload />}
          sx={{ maxWidth: '100%', maxHeight: '2rem', marginInline: 'auto', fontSize: '0.7rem' }}
        >
          {noRegisterForm.file.value ? noRegisterForm.file.value.name : 'Cargar PDF factura'}
        </Button>
      </label>
      <Typography
        variant="h6"
        textAlign="center"
      >
        O envía tu factura al correo
        <Link component="a" href="mailto:facturas@fingo.cl" color="primary"> facturas@fingo.cl</Link>
      </Typography>
      <LoadingButton
        variant="contained"
        color="primary"
        size="small"
        loading={loadingNoRegister}
        disabled={!noRegisterButtonReady}
        onClick={noRegisterMutation}
        sx={{
          width: '50%',
          alignSelf: 'center',
        }}
      >
        Enviar
      </LoadingButton>
    </Stack>
  );
};
EvaluateNoRegister.propTypes = {
  onCompleted: PropTypes.func,
};

EvaluateNoRegister.defaultProps = {
  onCompleted: () => {},
};
export default EvaluateNoRegister;
