import { useTextFieldInput, useIsMobile, useWindowWidth } from '@fingo/lib/hooks';
import React, { useState, useMemo } from 'react';
import { Stack, Typography, Box, Paper } from '@mui/material';
import YouTube from 'react-youtube';
import LoginLanding from '../../views/Login/LoginLanding';
import InvoiceSimulatorVariables from './components/InvoiceSimulatorVariables';
import SimulationAmounts from '../simulator/SimulationAmounts';

const SimulatorRegisterVideo = () => {
  const [amount, _setAmount] = useState(10000000);
  const [daysToPay, setDaysToPay] = useTextFieldInput(30);
  const [client, setClient] = useState();
  const [debtor, setDebtor] = useState();
  const isMobile = useIsMobile();
  const innerWidth = useWindowWidth();
  const aspectRatio = 9 / 16;
  const widthVideo = useMemo(() => {
    if (isMobile) {
      return '100%';
    }
    return 900;
  }, [isMobile]);
  const heightVideo = useMemo(
    () => (isMobile ? innerWidth * aspectRatio : widthVideo * aspectRatio),
    [isMobile],
  );

  return (
    <Box sx={{ backgroundColor: '#f0f0f0', minHeight: '100vh', padding: isMobile ? 2 : 6 }}>
      <Stack spacing={isMobile ? 3 : 6}>
        <Stack
          direction={isMobile ? 'column' : 'row'}
          spacing={3}
          alignItems="center"
          alignSelf="center"
          marginTop={2}
        >
          <YouTube
            videoId="gvYAA3Xe9T8"
            style={{
              width: '100%',
              height: heightVideo,
            }}
            opts={{
              width: widthVideo,
              height: heightVideo,
            }}
          />
          <LoginLanding
            paperSx={{
              width: { sm: 350 },
              borderRadius: '10px',
              mx: 'auto',
              left: '10%',
              padding: '2%',
            }}
            landingTag="Video-Landing"
          />
        </Stack>
        <Stack alignSelf="center" width={isMobile ? '100%' : '50%'} alignItems="center" spacing={2}>
          <Typography align="left" variant="h3">
            <Typography component="span" color="primary" variant="h3">Simula</Typography> {' '}
            automáticamente el anticipo de tus facturas.
          </Typography>
          <Paper elevation={3} sx={{ flexDirection: isMobile ? 'column' : 'row', padding: 2 }}>
            <Stack>
              <InvoiceSimulatorVariables
                setClient={setClient}
                setDebtor={setDebtor}
                amount={amount}
                _setAmount={_setAmount}
                daysToPay={daysToPay}
                _setDaysToPay={setDaysToPay}
                showSliders={false}
                elevation={0}
              />
              <SimulationAmounts
                client={client}
                debtor={debtor}
                amount={amount}
                days={daysToPay}
                showRegisterButton={false}
                elevation={0}
              />
            </Stack>
          </Paper>
        </Stack>
      </Stack>
    </Box>
  );
};

export default SimulatorRegisterVideo;
