import React from 'react';
import { Grid, Box, Button } from '@mui/material';
import { FingoLogo } from '@fingo/lib/assets';
import ContactLandingFooter from '@fingo/lib/components/footers/ContactLandingFooter';
import { useIsLogged } from '@fingo/lib/hooks';
import { isLoggedInQuery } from '@fingo/lib/apollo/cache/localQuery';
import { useLocation, useHistory } from 'react-router-dom';
import { ChurnOperationText, ChurnJoinFingoText } from '../typographys/churn-texts';
import TimelineStepsToEvaluateChurn from './components/TimelineStepsToEvaluateChurn';
import DisplayItemsChurn from './components/DisplayItemsChurn';
import LoginLanding from '../../views/Login/LoginLanding';

const Churn = () => {
  const location = useLocation();
  const history = useHistory();
  const isLogged = useIsLogged(isLoggedInQuery);
  const queryParams = new URLSearchParams(location.search);
  const numberOperation = queryParams.get('operation');

  const handleButtonClick = () => {
    history.push('/app/sales/factoring');
  };

  return (
    <Grid
      container
      spacing={4}
      justifyContent="center"
      alignItems="flex-start"
      sx={{ py: 2, backgroundColor: 'white', position: 'relative', zIndex: 1 }}
    >
      <Grid item md={11} xs={12}>
        <Grid container spacing={4} align="center">
          <Grid item lg={8} xs={12} textAlign="left" align="center">
            <Box width="100%">
              <ChurnOperationText numberOperation={numberOperation} />
            </Box>
            <Box width="100%" xs={12} sx={{ marginTop: { xs: 7, md: 2 }, marginBottom: { xs: 7 } }}>
              <TimelineStepsToEvaluateChurn isLogged={isLogged} />
            </Box>
          </Grid>
          <Grid item lg={4} md={6} xs={12}>
            <Box align="center">
              {!isLogged ? (
                <LoginLanding
                  paperSx={{
                    borderRadius: '10px',
                    maxWidth: '350px',
                    padding: '6%',
                  }}
                  landingTag="Promo-Landing"
                />
              ) : (
                <Button
                  id="landing-evaluate-invoices-button"
                  onClick={handleButtonClick}
                  align="center"
                  color="primary"
                  variant="contained"
                  size="large"
                  textAlign="center"
                  sx={{ marginTop: { md: '40%' }, marginLeft: { md: '40%' }, padding: { md: '35px' } }}
                >
                  Ver mis facturas disponibles!
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ flexDirection: 'column' }}
        >
          <Grid item xs={12}>
            <ChurnJoinFingoText />
          </Grid>
          <Grid item xs={12}>
            <DisplayItemsChurn />
          </Grid>
          <Grid item sx={{ marginTop: 10, marginBottom: 10 }}>
            <img src={FingoLogo} alt="Fingo Logo" style={{ maxWidth: '300px', alignSelf: 'center' }} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <ContactLandingFooter />
      </Grid>
    </Grid>
  );
};

export default Churn;
