import React from 'react';
import UploadIcon from '@mui/icons-material/Upload';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import TimelineWithIcons from '@fingo/lib/components/timeline/TimelineWithIcons';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const steps = [
  {
    text: 'Sube el PDF de tu factura junto a tu número de teléfono',
    Icon: UploadIcon,
  },
  {
    text: 'Te enviaremos una simulación por whatsapp',
    Icon: WhatsAppIcon,
  },
  {
    text: 'Aprueba la simulación en la aplicación de Fingo',
    Icon: CheckRoundedIcon,
  },
  {
    text: '¡Recibe tu dinero!',
    Icon: PaidOutlinedIcon,
  },
];

const TimelineStepsToEvaluate = () => (
  <TimelineWithIcons steps={steps} />
);

export default TimelineStepsToEvaluate;
