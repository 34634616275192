import { Grid } from '@mui/material';
import React from 'react';
import StepsToFinance from './components/StepsToFinance';
import LoginLanding from '../../views/Login/LoginLanding';
import PilotBackground from './components/PilotBackground';

const LandingRegisterForm = () => (
  <PilotBackground showPlane>
    <Grid container height="fit-content">
      <Grid
        container
        item
        xs={12}
        md={6}
        alignItems="flex-start"
        justifyContent="center"
        pt={{
          xs: 4,
          md: 10,
        }}
        display={{
          xs: 'none',
          md: 'inherit',
        }}
      >
        <StepsToFinance />
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        pt={{
          xs: 4,
          md: 6,
        }}
        justifyContent={{
          xs: 'center',
          md: 'auto',
        }}
      >
        <LoginLanding
          paperSx={{
            width: { xs: '90%', sm: 500 },
            borderRadius: '20px',
            mx: 'auto',
            py: 4,
            left: '50%',
            top: '50%',
          }}
        />
      </Grid>
    </Grid>
  </PilotBackground>
);

export default LandingRegisterForm;
