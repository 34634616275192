import React from 'react';
import UploadIcon from '@mui/icons-material/Upload';
import AutoFixHighRoundedIcon from '@mui/icons-material/AutoFixHighRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import TimelineWithIcons from '@fingo/lib/components/timeline/TimelineWithIcons';

const steps = [
  {
    text: 'Regístrate en el formulario de la derecha',
    Icon: UploadIcon,
  },
  {
    text: 'Selecciona tu factura a evaluar',
    Icon: AutoFixHighRoundedIcon,
  },
  {
    text: 'Recibe aprobación de riesgo',
    Icon: CheckRoundedIcon,
  },
  {
    text: '¡Recibe tu dinero!',
    Icon: PaidOutlinedIcon,
  },
];

const TimelineStepsToFinance = () => (
  <TimelineWithIcons steps={steps} />
);

export default TimelineStepsToFinance;
