import { useMutation } from '@apollo/client';
import { REGISTER_USER } from '@fingo/lib/graphql';
import { getFormFieldError, isPasswordValid } from '@fingo/lib/helpers';
import Check from '@mui/icons-material/Check';
import Close from '@mui/icons-material/Close';
import { InputAdornment, Typography } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import PropsTypes from 'prop-types';
import { formatRut } from 'react-rut-formatter';
import Cookies from 'universal-cookie';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/system';
import RegisterTextField from '@fingo/lib/components/inputs/RegisterTextField';
import { PasswordInput } from '@fingo/lib/components/inputs';
import { LoadingButton } from '@mui/lab';
import { useIsMobile } from '@fingo/lib/hooks';
import { PasswordStrength } from '../../utils';
import { useLogin } from '../../../hooks';

const QuickRegisterComponent = ({ onRegisterCompleted, origin }) => {
  const isMobile = useIsMobile();
  const inputVariant = useMemo(() => {
    if (isMobile) return 'filled';
    return 'standard';
  }, [isMobile]);
  const location = useLocation();
  const urlSearch = new URLSearchParams(location.search);
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    firstName: {
      value: '',
      error: '',
    },
    lastName: {
      value: '',
      error: '',
    },
    phoneNumber: {
      value: urlSearch.get('phoneNumber') || '',
      error: '',
    },
    password: {
      value: '',
      error: '',
    },
    email: {
      value: urlSearch.get('email') || '',
      error: '',
    },
  });
  const [loginGql, loadingLogin, errorForm] = useLogin(
    formData.email.value.toLowerCase(),
    formData.password.value,
  );
  const cookies = new Cookies();
  const translateRegisterError = (error) => {
    if (error.message === 'user already exist') {
      const emailMessage = 'Ese email ya tiene una cuenta. Intenta con otro o inicia sesión.';
      setFormData((prev) => ({
        ...prev,
        email: {
          ...prev.email,
          error: emailMessage,
        },
      }));
      return emailMessage;
    }
    return 'Hubo un error al crear tu cuenta. Intenta más tarde.';
  };
  const [registerError, setRegisterError] = useState('');
  const [registerUser, { loading: loadingRegisterUser }] = useMutation(REGISTER_USER, {
    variables: {
      email: formData.email.value.toLowerCase(),
      firstName: formData.firstName.value,
      lastName: formData.lastName.value,
      password: formData.password.value,
      phoneNumber: `${t('Phone prefix')}${formData.phoneNumber.value}`,
      utmCampaign: cookies.get('utm_campaign') || null,
      origin,
    },
    onError: (error) => {
      setRegisterError(translateRegisterError(error));
    },
    onCompleted: async () => {
      await loginGql();
      onRegisterCompleted();
    },
  });

  const handleChange = useCallback((e) => {
    let { value } = e.target;
    const { name, type } = e.target;
    if (name === 'companyRut') value = formatRut(value);
    const fieldError = getFormFieldError(value, type, name);
    if (fieldError.isError) {
      setFormData((oldState) => ({
        ...oldState,
        [name]: { ...oldState[name], error: fieldError.message, value },
      }));
    } else {
      setFormData((oldState) => ({
        ...oldState,
        [name]: { ...oldState[name], error: '', value },
      }));
      if (name === 'email') {
        setRegisterError('');
      }
    }
  }, []);
  const isButtonReady = useMemo(() => {
    for (let i = 0; i < Object.values(formData).length; i += 1) {
      const element = Object.values(formData)[i];
      if (element.value === '' || element.error !== '') {
        return false;
      }
    }
    if (!isPasswordValid(formData.password.value)) {
      return false;
    }
    return true;
  }, [formData]);

  const setEndAdornment = useCallback(({ element }) => {
    if (element.value !== '') {
      if (element.error === '') {
        return <InputAdornment position="end"><Check color="success" /></InputAdornment>;
      }
      return <InputAdornment position="end"><Close color="error" /></InputAdornment>;
    }
    return null;
  }, []);
  const onSubmit = useCallback((event) => {
    event.preventDefault();
    registerUser();
  }, []);
  return (
    <form
      onSubmit={onSubmit}
      id="loginForm"
    >
      <Stack
        alignItems="center"
        spacing={{
          xs: 0.5,
        }}
        width="100%"
      >
        <RegisterTextField
          name="firstName"
          validationtype="text"
          type="text"
          label="Nombre"
          variant={inputVariant}
          value={formData.firstName.value}
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              setEndAdornment({ element: formData.firstName })
            ),
          }}
        />
        <RegisterTextField
          name="lastName"
          validationtype="text"
          type="text"
          label="Apellido"
          variant={inputVariant}
          value={formData.lastName.value}
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              setEndAdornment({ element: formData.lastName })
            ),
          }}
        />
        <RegisterTextField
          name="phoneNumber"
          validationtype="phone"
          type="number"
          label="Número de teléfono"
          variant={inputVariant}
          value={formData.phoneNumber.value}
          onChange={handleChange}
          InputProps={{
            startAdornment: <InputAdornment position="start">+{t('Phone prefix')}</InputAdornment>,
            endAdornment: (
              setEndAdornment({ element: formData.phoneNumber })
            ),
          }}
        />
        <RegisterTextField
          name="email"
          validationtype="email"
          type="email"
          label="Email"
          variant={inputVariant}
          value={formData.email.value}
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              setEndAdornment({ element: formData.email })
            ),
          }}
        />
        <PasswordInput
          Component={RegisterTextField}
          name="password"
          label="Contraseña"
          variant={inputVariant}
          value={formData.password.value}
          onChange={handleChange}
          error={!!errorForm?.password}
          helperText={errorForm.password}
          sx={{
            width: '100%',
          }}
        />
        <PasswordStrength password={formData.password.value} />
        {registerError && (
        <Typography
          fontWeight={500}
          color="error"
        >
          {registerError}
        </Typography>
        )}
        <LoadingButton
          variant="contained"
          color="primary"
          size="small"
          type="submit"
          loading={loadingLogin || loadingRegisterUser}
          disabled={!isButtonReady}
          id="register-create-account"
        >
          Crear cuenta
        </LoadingButton>
      </Stack>
    </form>
  );
};

QuickRegisterComponent.propTypes = {
  onRegisterCompleted: PropsTypes.func.isRequired,
  origin: PropsTypes.string,
};

QuickRegisterComponent.defaultProps = {
  origin: 'fingo',
};

export default QuickRegisterComponent;
