import FingoSlider from '@fingo/lib/components/slider/FingoSlider';
import { Box, FilledInput, FormControl, InputLabel, Paper, Grid } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import AutoCompleteMasterEntity from '@fingo/lib/components/inputs/AutoCompleteMasterEntity';
import { useInvoiceSimulator } from '../../../hooks';

const InvoiceSimulatorVariables = ({
  setClient,
  setDebtor,
  amount,
  _setAmount,
  daysToPay,
  _setDaysToPay,
  showSliders,
  elevation,
}) => {
  const {
    formatLabelMoney,
    formatLabelDays,
    sliderAmount,
    setAmount,
    setSliderAmount,
    showSliderAmount,
    setDaysToPay,
    maxAmount,
    minAmount,
    maxDaysToPay,
    minDaysToPay,
  } = useInvoiceSimulator({ amount, _setAmount, _setDaysToPay });

  return (
    <Paper
      elevation={elevation}
      width="100%"
      sx={{
        height: '100%',
        py: {
          xs: 1,
          md: 2,
          lg: 2,
        },
        px: {
          xs: 1,
          md: 4,
          lg: 6,
        },
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Grid
        container
        columnSpacing={2}
        rowSpacing={1}
        height="fit-content"
      >
        <Grid item xs={12} md={6}>
          <AutoCompleteMasterEntity setEntity={setClient} label="Busque su empresa por nombre o rut" />
        </Grid>
        <Grid item xs={12} md={6}>
          <AutoCompleteMasterEntity setEntity={setDebtor} label="Busque la empresa de su cliente por nombre o rut" />
        </Grid>
        <Grid
          item
          xs={12}
          md={showSliders ? 3 : 6}
          mt={{
            xs: 0,
            md: 4,
          }}
        >
          <FormControl fullWidth variant="filled">
            <InputLabel htmlFor="amount-input">Monto</InputLabel>
            <FilledInput
              id="amount-input"
              onChange={setAmount}
              value={formatLabelMoney(amount)}
              color="background.dark"
              disableUnderline
              fullWidth
            />
          </FormControl>
        </Grid>
        <Grid container item xs={12} md={9} mt={4} display={showSliders ? 'inherit' : 'none'}>
          <Box px={2} width="100%">
            <FingoSlider
              aria-label="amount"
              name="invoice-amount"
              valueLabelDisplay="on"
              scale={showSliderAmount}
              valueLabelFormat={formatLabelMoney}
              onChange={setSliderAmount}
              value={sliderAmount}
              max={maxAmount}
              min={minAmount}
              marks={[
                { value: minAmount, label: `$${parseInt(minAmount / 1000000, 10).toString()}` },
                { value: maxAmount, label: `$${parseInt(maxAmount / 1000000, 10).toString()}MM` },
              ]}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={showSliders ? 3 : 6}
          mt={{
            xs: 0,
            md: 4,
          }}
        >
          <FormControl fullWidth variant="filled">
            <InputLabel htmlFor="date-to-pay-input">Días para pago</InputLabel>
            <FilledInput
              id="date-to-pay-input"
              onChange={setDaysToPay}
              color="background.dark"
              type="number"
              value={Number(daysToPay).toString()}
              disableUnderline
              fullWidth
              min={minDaysToPay}
              max={maxDaysToPay}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={9} mt={4} display={showSliders ? 'inherit' : 'none'}>
          <Box px={2} width="100%">
            <FingoSlider
              aria-label="days-to-pay"
              name="invoice-days-to-pay"
              valueLabelDisplay="on"
              valueLabelFormat={formatLabelDays}
              onChange={setDaysToPay}
              value={daysToPay}
              max={maxDaysToPay}
              min={minDaysToPay}
              marks={[
                { value: minDaysToPay, label: formatLabelDays(minDaysToPay) },
                { value: maxDaysToPay, label: formatLabelDays(maxDaysToPay) },
              ]}
            />
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

InvoiceSimulatorVariables.propTypes = {
  setClient: PropTypes.func.isRequired,
  setDebtor: PropTypes.func.isRequired,
  amount: PropTypes.number.isRequired,
  _setAmount: PropTypes.func.isRequired,
  daysToPay: PropTypes.number.isRequired,
  _setDaysToPay: PropTypes.func.isRequired,
  showSliders: PropTypes.bool.isRequired,
  elevation: PropTypes.number,
};

InvoiceSimulatorVariables.defaultProps = {
  elevation: 4,
};

export default InvoiceSimulatorVariables;
