import React, { useEffect, useRef, useState } from 'react';
import { UPDATE_LOKAL_DEBTOR } from '@fingo/lib/graphql';
import { useMutation } from '@apollo/client';
import { Card, Grid, Stack, Typography } from '@mui/material';
import { BackgroundMosaic } from '@fingo/lib/assets';
import { BackSide, Flippy, FrontSide } from 'react-flippy';
import NationalTaxServiceCredentialsForm from '@fingo/lib/components/forms/NationalTaxServiceCredentialsForm';
import LokalLogo from './LokalForm/LokalLogo';

const Background = () => (
  <div
    style={{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundImage: `url(${BackgroundMosaic})`,
      opacity: 0.3,
      zIndex: -1,
    }}
  />
);

const LokalCredentialsFlipCard = () => {
  const [updateLokalDebtor] = useMutation(UPDATE_LOKAL_DEBTOR);
  const [openBackSide, setOpenBackSide] = useState(false);
  const cardRef = useRef(null);

  const onCompletedHandler = (nationalIdentifier) => {
    updateLokalDebtor({ variables: { nationalIdentifier } });
    setOpenBackSide(true);
  };

  useEffect(() => {
    if (openBackSide) {
      cardRef.current.toggle();
    }
  }, [openBackSide]);

  return (
    <Grid container alignItems="center" justifyContent="center" height="100%">
      <Background />
      <Flippy style={{ backgroundColor: 'transparent' }} flipOnClick={false} ref={cardRef}>
        <FrontSide style={{ borderRadius: 18, padding: 0 }}>
          <Card
            elevation={0}
            sx={{
              overflow: 'scroll',
              '::-webkit-scrollbar': {
                display: 'none',
              },
              borderRadius: 3,
              maxWidth: 500,
            }}
          >
            <Stack
              direction="column"
              alignItems="center"
              spacing={3}
              sx={{
                py: 4,
                px: 6,
                width: '100%',
              }}
            >
              <LokalLogo />
              <Typography variant="h5" align="center" sx={{ fontWeight: 400 }}>
                Lokal te ha enviado una invitación para conectar tus datos al Servicio de Impuestos
                Internos.
              </Typography>
              <NationalTaxServiceCredentialsForm
                source="Lokal"
                onCompleted={onCompletedHandler}
                typographyColor="text"
                fullWidth
                hideTitle
              />
            </Stack>
          </Card>
        </FrontSide>
        <BackSide style={{ borderRadius: 18, padding: 0 }}>
          <Grid container alignItems="center" justifyContent="center" height="100%">
            <Card
              elevation={0}
              sx={{
                overflow: 'scroll',
                '::-webkit-scrollbar': {
                  display: 'none',
                },
                borderRadius: 3,
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Stack
                direction="column"
                alignItems="center"
                spacing={3}
                sx={{
                  py: 4,
                  px: 6,
                  width: '100%',
                }}
              >
                <LokalLogo />
                <Typography variant="h5" align="center" sx={{ fontWeight: 400 }}>
                  ¡Datos ingresados con éxito!
                </Typography>
              </Stack>
            </Card>
          </Grid>
        </BackSide>
      </Flippy>
    </Grid>
  );
};

export default LokalCredentialsFlipCard;
