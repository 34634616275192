import React from 'react';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import PilotBackground from './components/PilotBackground';
import TimelineStepsToEvaluate from './components/TimelineStepsToEvaluate';
import EvaluateNoRegister from './components/EvaluateNoRegister';

const LandingRegisterWhatsappEvaluation = () => (
  <PilotBackground showPlane>
    <Grid
      container
      py={{
        xs: 2,
        md: 8,
      }}
      rowSpacing={2}
      bgcolor={{
        xs: 'fingoWhite.main',
        md: 'inherit',
      }}
      direction={{
        xs: 'column-reverse',
        md: 'row',
      }}
    >
      <Grid
        container
        item
        xs={12}
        md={6}
        px={4}
        alignItems="flex-start"
        justifyContent="center"
      >
        <Box
          maxWidth={550}
          mt={{
            md: 4,
          }}
        >
          <Typography variant="h1" paragraph align="left" width="100%">
            <Typography variant="h1" component="span" color="primary">¡Obtén financiamiento</Typography>
            {' '}en menos de 1 día!
          </Typography>
          <Typography variant="h4" paragraph align="left" width="100%">
            En 4 simples pasos:
          </Typography>
          <Box
            width="100%"
            bgcolor="fingoWhite.main"
            borderRadius={4}
            px={2}
            py={4}
          >
            <TimelineStepsToEvaluate />
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        px={4}
      >
        <Stack
          spacing={4}
          alignItems={{
            xs: 'center',
            md: 'flex-start',
          }}
        >
          <Typography color="primary" variant="h1">
            Evalúa facturas sin registrarte
          </Typography>
          <Paper
            sx={{
              width: { xs: 300, sm: 400 },
              py: 4,
              px: { xs: 2, md: 6 },
            }}
            elevation={4}
            height="fit-content"
          >
            <EvaluateNoRegister />
          </Paper>
        </Stack>
      </Grid>
    </Grid>
  </PilotBackground>
);

export default LandingRegisterWhatsappEvaluation;
